import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import HashLoader from 'react-spinners/HashLoader';
// import '..css';
// import { Homepage, StaticPage,ContactUs, ProductsPage } from '../Pages';
// import NewsDetailPage from '../Pages/News/NewsDetail/NewsDetailPage';
// import NewsPage from '../Pages/News/NewsPage';
// import ProductDetailPage from '../Pages/ProductDetailPage/ProductDetailPage'

const Homepage = lazy(() => import('../Pages/Homepage/Homepage'))
const StaticPage = lazy(() => import('../Pages/StaticPage/StaticPage'))
const ContactUs = lazy(() => import('../Pages/ContactUs/ContactUs'))
const ProductsPage = lazy(() => import('../Pages/ProductsPage/ProductsPage'))

const ProductDetailPage = lazy(() => import('../Pages/ProductDetailPage/ProductDetailPage'))
const NewsPage = lazy(() => import('../Pages/News/NewsPage'))
const NewsDetailPage = lazy(() => import('../Pages/News/NewsDetail/NewsDetailPage'))

class AppRouter extends React.Component {
  
  render() {
    return (
      <BrowserRouter basename="/" forceRefresh={true}>
          <Suspense fallback={
            <div className = "startingLoader">
              <HashLoader color="#98bd28" />
            </div>
          }>
            <Routes>

              <Route path="/" element={<Homepage />} />
              {/* <Route path="about-us" element={<StaticPage />} />
              <Route path="disclaimer" element={<Disclaimer />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms" element={<Terms />} /> */}

              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/vaahan-news" element={<NewsPage />} />
              <Route path="/vaahan-news/:slug" element={<NewsDetailPage />} />
              <Route path="/products/:slug" element={<ProductDetailPage />} />
              <Route path="/product-detail" element={<ProductDetailPage />} />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/:slug" element={<StaticPage />} />

              
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </Suspense>
      </BrowserRouter>
    )
  }
}

export default AppRouter;