import React, { useEffect } from 'react';
import AppRouter from './Routes/AppRouter';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import 'react-toastify/dist/ReactToastify.css';
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";

const App = () => {

  return (
    <>
    <LightgalleryProvider
      lightgallerySettings={
        {
          youtubeThumbSize: "maxresdefault",
          counter: false,
          thumbnail: false,
          videoMaxWidth:'70vw',
          download: false,
          zoom: false,
          actualSize: false,
          hideBarsDelay: 0,
        }
      }
    >
      <AppRouter />
      <ToastContainer
        autoClose={2000}
        position='bottom-left'
        newestOnTop={true}
        hideProgressBar={true}
        draggable={false}
        // limit={1}
        style={{
          textAlign: 'center',
        }}
      />
      </LightgalleryProvider>
    </>
  )
};

export default App;
